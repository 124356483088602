<template>
  <div class="tree-wrap">
    <el-tree-item :data="data"></el-tree-item>
  </div>
</template>

<script>
import TreeItem from "./tree-item.vue";
export default {
  name: "el-tree",
  components: {
    [TreeItem.name]: TreeItem,
  },
  props: {
    data: Array,
  },
  provide() {
    return {
      itemClick: (data) => {
        // 把数据传递给外部调用树形控件的组件
        this.$emit("node-click", data);
      },
    };
  },
  created() {
    // this.getStore();
  },
  methods: {
  //   async getStore() {
  //     const data = await this.$API.store.getStoreCategory({
  //       object: {
  //         storeId: this.$route.params.id,
  //       },
  //     });
  //     console.log(data.data.data.categoriesCatalog);
  //     this.data = data.data.data.categoriesCatalog;
  //   },
  },
};
</script>

<style>
</style>