<template>
  <div class="store-category">
    <div class="category">
      <div class="left">
        <div class="title">
          <p>CLASSIFICATION</p>
          <h3><i></i>公司类目<i></i></h3>
        </div>

        <!-- <el-tree :data="data" @node-click="handleClickAction"></el-tree> -->

        <div class="tree">
          <div v-for="list in classData" :key="list.categoryId" class="stair">
            <p
              class="stair-cont"
              @click="ClickAction(list)"
              :style="list.sub ? '' : 'text-indent: 32px;'"
              :class="list.pitchOn ? 'pitchOn' : ''"
            >
              <img
                :src="
                  list.more
                    ? require('@/assets/images/pack-up.png')
                    : require('@/assets/images/an.png')
                "
                alt=""
                v-if="list.sub"
                @click.stop="Show(list)"
              />{{ list.categoryName }}<i v-show="show"></i>
            </p>
            <el-collapse-transition>
              <div v-show="list.more">
                <ul
                  v-for="second in list.subItemList"
                  :key="second.categoryId"
                  class="second"
                >
                  <p
                    class="second-cont"
                    @click="ClickAction(second)"
                    :class="second.pitchOn ? 'pitchOn' : ''"
                  >
                    {{ second.categoryName }}
                    <svg-icon
                      icon-class="ic-arrow-b"
                      v-if="second.sub"
                      @click.stop="Show(second)"
                      :style="
                        second.more
                          ? 'transform: rotateZ(90deg)'
                          : 'transform: rotateZ(0deg)'
                      "
                    />
                  </p>
                  <el-collapse-transition>
                    <div v-show="second.more">
                      <li
                        v-for="threeLevel in second.subItemList"
                        :key="threeLevel.categoryId"
                        @click="ClickAction(threeLevel)"
                        :class="threeLevel.pitchOn ? 'pitchOn' : ''"
                      >
                        {{ threeLevel.categoryName }}
                      </li>
                    </div>
                  </el-collapse-transition>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
      <div class="right">
        <goods-list :categoryId="categoryId" :like="$route.query.storeLike" />
      </div>
    </div>
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import ElTree from "@/components/El-tree";
export default {
  name: "store-category",
  components: { GoodsList, ElTree },
  data() {
    return {
      classData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      categoryId: [0],
      show: true,
      like: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // this.like = val.query.like;
        console.log(this.categoryId);
        if (val.query.storeLike != undefined) {
          this.show = !this.show;
          for (const list of this.classData) {
            list.pitchOn = false;
            if (list.sub) {
              for (const sub of list.subItemList) {
                sub.pitchOn = false;
                if (sub.sub) {
                  for (const item of sub.subItemList) {
                    item.pitchOn = false;
                  }
                }
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getStore();
  },
  methods: {
    async getStore() {
      const data = await this.$API.store.getStoreCategory({
        object: {
          storeId: this.$route.params.id,
        },
      });
      this.classData = data.data.data.categoriesCatalog;
      for (const list of this.classData) {
        list.more = false;
        list.pitchOn = false;
        // console.log(list);
        if (list.subItemList != null) {
          list.sub = true;
          for (const sub of list.subItemList) {
            sub.more = false;
            sub.pitchOn = false;
            if (sub.subItemList != null) {
              sub.sub = true;
              for (const item of sub.subItemList) {
                item.pitchOn = false;
              }
            } else {
              sub.sub = false;
            }
          }
        } else {
          list.sub = false;
        }
      }
    },
    Show(list) {
      this.show = !this.show; //bug 不知怎么解决 勿删
      list.more = !list.more;
    },
    ClickAction(list, id) {
      this.show = !this.show; //bug 不知怎么解决 勿删
      if (this.$route.query.storeLike) {
        this.$router.push({
          path: `/Store/HomePage/${this.$route.params.id}/StoreCategory`,
          query: {
            enterpriseId: this.$route.query.enterpriseId,
          },
        });
      }
      let arr = [];
      if (list.rank === 1) {
        //点击一类
        if (list.sub) {
          arr.push(list.categoryId);
          for (let i = 0; i < list.subItemList.length; i++) {
            arr.push(list.subItemList[i].categoryId);
            if (list.subItemList[i].subItemList != null) {
              for (let n = 0; n < list.subItemList[i].subItemList.length; n++) {
                arr.push(list.subItemList[i].subItemList[n].categoryId);
              }
            }
          }
        } else {
          arr.push(list.categoryId);
        }
      } else if (list.rank === 2) {
        //二类
        arr.push(list.categoryId);
        if (list.subItemList != null) {
          for (let n = 0; n < list.subItemList.length; n++) {
            arr.push(list.subItemList[n].categoryId);
          }
        }
      } else {
        arr.push(list.categoryId);
      }
      // console.log(list);
      // list.more = !list.more;
      // if (!list.sub) {
      for (const list of this.classData) {
        list.pitchOn = false;
        if (list.sub) {
          for (const sub of list.subItemList) {
            sub.pitchOn = false;
            if (sub.sub) {
              for (const item of sub.subItemList) {
                item.pitchOn = false;
              }
            }
          }
        }
      }
      list.pitchOn = true;
      this.categoryId = arr;
      // }
    },
    handleClickAction(value) {
      if (value.rank === 3) {
        this.categoryId = value.categoryId;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.store-category {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 84px;
  .category {
    width: 1200px;
    display: flex;
    .left {
      width: 200px;
      border: 1px solid #dddddd;
      background: #f8f8f8;

      .title {
        height: 90px;
        border-bottom: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          font-size: 12px;
          line-height: 16px;
          color: #999999;
          margin-bottom: 4px;
        }
        h3 {
          font-size: 22px;
          line-height: 28px;
          color: #333333;
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 4px;
            height: 4px;
            background: #444444;
            border-radius: 50%;
            margin: 0 8px;
          }
        }
      }
      .tree {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        .stair {
          font-size: 14px;
          border-bottom: 1px solid #e4e4e4;
          .stair-cont {
            cursor: pointer;
            height: 40px;
            line-height: 40px;
            color: #111;
            img {
              margin: 0 8px 0 12px;
            }
          }

          .stair-cont:hover {
            background: #f3f3f3;
            text-decoration: underline;
            color: #00c45c;
          }
          .second {
            background: #ffffff;
            .second-cont {
              cursor: pointer;
              height: 32px;
              align-items: center;
              justify-content: space-between;
              display: flex;
              padding-left: 46px;
              padding: 0 16px 0 46px;
              color: #333;
              .svg-icon {
                color: #888888;
                font-size: 12px;
                transition: all 0.2s;
                height: 100%;
              }
              .svg-icon:hover {
                color: #333333;
              }
            }
            .second-cont:hover {
              background: #f3f3f3;
              text-decoration: underline;
              color: #00c45c;
            }
            li {
              line-height: 30px;
              color: #666666;
              // padding-bottom: 10px;
              text-indent: 60px;
              cursor: pointer;
            }
            li:hover {
              background: #f2f2f2;
              text-decoration: underline;
              color: #00c45c;
            }
          }
          .second:first-child {
            border-top: 1px solid #e4e4e4;
          }
        }
        // .stair:last-child {
        // border-bottom: none;
        // .stair-cont {
        // border-bottom: 1px solid #e4e4e4;
        // }
        // }
        .pitchOn {
          background-color: #f2f2f2 !important;
          color: #00c45c !important;
          font-weight: bold !important;
        }
      }
      // ::v-deep .el-tree {
      //   background: #f6f6f6;
      //   .el-tree-node__content {
      //     height: 40px;
      //   }
      //   .el-tree-node__children {
      //     background: #fff !important;
      //   }
      //   .el-tree-node__children:hover {
      //     background: none;
      //   }
      //   .el-tree-node__content:hover {
      //     background: none;
      //   }
      //   .key-word {
      //     color: red;
      //   }
      // }
    }
    .right {
      margin: 20px 0 0 20px;
      #goods-list {
        width: 980px;
        ::v-deep.goods {
          margin-top: 16px;
          border-top: 1px solid #e8e8e8;
        }
      }
      ::v-deep .goods-box {
        width: 246px;
      }
      ::v-deep .goods-box:nth-child(4n) {
        border-right: none !important;
        width: 240px;
      }
      ::v-deep .goods-box:nth-child(5n) {
        border-right: 1px solid #e8e8e8 !important;
        width: 246px !important;
      }
    }
  }
}
</style>