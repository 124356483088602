<template>
  <ul>
    <li
      v-for="(item, index) in data"
      :key="index"
      :class="['tree' + item.rank, '' + index]"
      ref="P"
    >
      <p
        :class="'tree-item' + item.rank"
        class="tree-item"
        @click="triggerItemAction(index, item), spanOn(index, item)"
      >
        <span :class="Id === item.categoryId ? 'tree-item3Click' : ''">{{
          item.categoryName
        }}</span>
        <svg-icon
          icon-class="ic-arrow-b"
          class="arrow"
          :class="{ active: item.showChildren }"
          v-if="item.subItemList && item.subItemList.length > 0"
        ></svg-icon>
      </p>
      <!-- 下一级 -->
      <el-tree-item
        v-if="
          item.showChildren && item.subItemList && item.subItemList.length > 0
        "
        :data="item.subItemList"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "el-tree-item",
  props: {
    // 接收要展示的列表
    data: Array,
  },
  inject: ["itemClick"],
  // watch: {
  //   data: {
  //     handler: function (val) {
  //       console.log(this.list);
  //     },
  //     immediate: true,

  //   },
  // },
  data() {
    return {
      Id: "",
      // list: this.data.map((item) => ({
      //   ...item,
      //   showChildren: false,
      // })),
    };
  },
  created() {},
  mounted() {
    // console.log(this.data);
    // this.list = this.data.map((item) => ({
    //   ...item,
    //   showChildren: false,
    // }));
  },
  methods: {
    // 展开，收起 下一级的点击事件
    triggerItemAction(index, item) {
      item.showChildren = !item.showChildren;

      // 告诉index.vue/ ElTree组件
      this.itemClick(this.data[index]);

      // const {showChildren, ...props} = item;
      // this.itemClick(props);
    },
    spanOn(index, i) {
      console.log(index);

      if (i.rank === 3) {
        this.Id = i.categoryId;
        this.twoTree =
          this.$refs.P[index].parentNode.parentNode.className.slice(6);
        console.log(this.twoTree);
      }
    },
  },
};
</script>

<style scoped lang="scss" >
ul {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.tree-item {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  justify-content: space-between;
}
.tree-item .arrow {
  font-size: 12px;
  color: #666666;
  transition: all 0.2s;
  margin-right: 8px;
}
.tree-item .arrow.active {
  transform: rotate(90deg);
}
.tree-item1 {
  border-bottom: 1px solid #e4e4e4;
  padding-left: 12px;
}
.tree2 {
  background: #fff;
  transition: all 0.2s;
  padding-left: 26px;
  .tree-item3 {
    padding-left: 14px;
  }
  .tree-item3:hover {
    color: #00AEEB;
  }
  .tree-item3Click {
    color: #00AEEB;
  }
}
</style>



